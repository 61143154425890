import Link from 'next/link'
import Image from 'next/image'
import styles from './BlogCard.module.scss'

export default function BlogCard(props) {
  return (
    <Link 
      href={`/blog/${props.url}`}
      prefetch={false}
    >
      <div className={`${styles.card} ${props.class ? styles[props.class] : ''}`}>
        <div className={styles.card__imageContainer}>
          <Image
            src={props.image}
            alt={`blog post background image for ${props.id}`} // Use props.id instead of props.key
            fill
            sizes="
              (max-width: 733px) 100vw,
              (max-width: 1087px) 50vw,
              460px"
          />
        </div>
        <div className={styles.card__body}>
          <div className={styles.card__title}>
            {props.title}
          </div>
        </div>
      </div>
    </Link>
  );
}

