import Card from "../../components/card";
import styles from "./WhyPurpleLotus.module.scss";

import ArrowRight from "../../components/icons/arrow-right.svg";
import ArrowLeft from "../../components/icons/arrow-left.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Navigation, Pagination, FreeMode } from "swiper";

import Button from "../../components/Button";

import family from "../../components/icons/animated/family.json";
import calendar from "../../components/icons/animated/calendar.json";
import verified from "../../components/icons/animated/verified.json";
import deliveryTruck from "../../components/icons/animated/delivery-truck.json";
import shield from "../../components/icons/animated/shield.json";
import information from "../../components/icons/animated/information.json";
import priceTag from "../../components/icons/animated/tag.json";
import support from "../../components/icons/animated/support.json";
import marijuana from "../../components/icons/animated/marijuana.json";

const slidesData = [
  {
    title: "Family-owned & Operated",
    animatedIcon: family,
    link: "About us",
    src: "/about",
    content: "We are a family-owned dispensary, founded in 2010. When you visit us, we treat you like family."
  },
  {
    title: "Open 365 Days",
    animatedIcon: calendar,
    link: "Locations & Hours",
    src: "/pickup",
    content: "Our dispensary, pickup, and delivery services are available everyday of the year, including holidays."
  },
  {
    title: "Hundreds of Products",
    animatedIcon: marijuana,
    link: "Shop Now",
    src: "/shop",
    content: "We offer the largest product selection in San Jose, which includes the Bay's most popular brands."
  },
  {
    title: "Voted Best Dispensary",
    animatedIcon: verified,
    link: "Learn More",
    src: "/blog/celebrating-excellence-purple-lotus-voted-best-dispensary-in-northern-california",
    content: 'Voted Best Boutique Cannabis Retailer in Northern California by Weedmaps’ "Best of 2024" awards.'
  },
  {
    title: "Knowledgeable Budtenders",
    animatedIcon: information,
    link: "Get in Touch",
    src: "/contact",
    content: "Whether you're a seasoned connoisseur or are new to cannabis, our budtenders can answer any of your questions."
  },
  {
    title: "Reliable Delivery",
    animatedIcon: deliveryTruck,
    link: "Delivery",
    src: "/weed-delivery",
    content: "Voted best cannabis delivery service in Silicon Valley, we excel at providing prompt, efficient, and friendly service."
  },
  {
    title: "Outstanding Service",
    animatedIcon: support,
    link: "Contact Support",
    src: "/contact",
    content: "Our dedicated team is always ready to assist you and ensure your experience with us is nothing short of excellent."
  },
  {
    title: "Secure Transactions",
    animatedIcon: shield,
    link: "Payment Options",
    src: "/payment-options",
    content: "We provide multiple payment options, including cash, credit, secure 1-click payments, and have on-site ATMs."
  },
  {
    title: "Exclusive Deals",
    animatedIcon: priceTag,
    link: "Deals & Specials",
    src: "/deals",
    content: "Subscribe for exclusive deals & rewards. We also offer daily deals and discounts for students, seniors, and veterans."
  }
];

const swiperConfig = {
  modules: [Navigation, Pagination, FreeMode],
  className: styles.whySwiper,
  spaceBetween: 15,
  touchEventsTarget: 'wrapper',
  breakpoints: {
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 14,
    },
    550: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 10,
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 10,
    },
  },
  navigation: {
    prevEl: `#why-prev`,
    nextEl: `#why-next`,
    clickable: true,
  },
};

export default function WhyPurpleLotus() {
  return (
    <div className={styles.container}>
      <div className={styles.rowHeader}>
        <h2>Live, <span>Love, </span><span>Lotus.</span></h2>
      </div>

      <Swiper {...swiperConfig}>
        <div className={styles.prev}>
          <Button type="button" id="why-prev" className="slider-arrow" ariaLabel="Previous Slide">
            <ArrowLeft />
          </Button>
        </div>
        <div className={styles.next}>
          <Button type="button" id="why-next" className="slider-arrow" ariaLabel="Next Slide">
            <ArrowRight />
          </Button>
        </div>

        {slidesData.map((slide, index) => (
          <SwiperSlide className={styles.slide} key={index}>
            <Card
              title={slide.title}
              animatedIcon={slide.animatedIcon}
              link={slide.link}
              src={slide.src}
              gap="0.5"
            >
              {slide.content}
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
