import { useEffect, useContext } from "react";
import Head from "next/head";
import { NextSeo } from "next-seo";

import StoreContext from "../context/store";
import { AlgoliaSearchProvider } from "../context/algolia";
import { useSpecials } from "../context/specials";

import Hero from "../components/hero";
import Specials from "../components/specials/Specials";
import BlogSlider from "../components/sliders/BlogSlider";
import ProductSlider from "../components/sliders/ProductSlider";
import BrandSlider from "../components/sliders/BrandSlider";
import CategorySlider from "../components/sliders/CategorySlider";
import WhyPurpleLotus from "../components/sliders/WhyPurpleLotus";

import { getServerState } from "react-instantsearch-hooks-server";
import { renderToString } from "react-dom/server";

async function getSliderState({ filters, initialStore }) {
  return await getServerState(
    <AlgoliaSearchProvider>
      <ProductSlider filters={filters} initialStore={initialStore} />
    </AlgoliaSearchProvider>, { renderToString }
  );
}

export async function getServerSideProps({ req }) {
  const { sanityClient } = await import("../lib/Sanity");
  const posts = await sanityClient.fetch(
    `*[_type == "post"] | order(_createdAt desc) [0..6] {
      _id,
      title,
      slug,
      createdAt,
      categories[] -> { title },
      mainImage
    }`
  );
  const initialStore = req.cookies.JANE_STORE || 4398;
  // Get individual SSR state for each slider
  const [flower, preroll] = await Promise.all([
    getSliderState({ filters: 'root_types:"flower"', initialStore }),
    getSliderState({ filters: 'root_types:"pre-roll"', initialStore }),
  ]);

  return {
    props: {
      posts,
      initialStore,
      sliders: {
        flower,
        preroll,
      },
    },
  };
}

export default function HomePage({ posts, sliders, initialStore }) {
  const { store } = useContext(StoreContext);
  const { specials, fetchAllSpecials, loading, error } = useSpecials();

  // Fetch Jane specials
  useEffect(() => {
    if (store) {
      fetchAllSpecials(store);
    }
  }, [store, fetchAllSpecials]);

  return (
    <>
      <header>
        <Head>
          <link rel="preconnect" href="https://cdn.sanity.io" />
          <link rel="dns-prefetch" href="https://cdn.sanity.io" />
        </Head>
        <NextSeo
          title="Shop Cannabis Delivery and In-Store Pickup | Purple Lotus"
          description="Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle."
          canonical="https://plpcsanjose.com"
          openGraph={{
            url: "https://plpcsanjose.com",
            title: "Shop Cannabis Delivery and In-Store Pickup | Purple Lotus",
            description:
              "Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle.",
            images: [
              {
                url: "https://plpcsanjose.com/images/purple-lotus-dispensary-building.jpg",
                width: 800,
                height: 600,
                alt: "Purple Lotus Cannabis Dispensary",
                type: "image/jpeg",
              },
            ],
            siteName: "Purple Lotus",
          }}
        />

        <Hero />
      </header>
      <main>
        <section className="container-row flex-column gap-300">
          <Specials
            title="Deals"
            specials={specials}
          />
          <BrandSlider />
          <CategorySlider title="Categories" />
          <AlgoliaSearchProvider serverState={sliders.flower}>
            <ProductSlider
              title="Flower"
              filters={`root_types:"flower"`}
              icon
              initialStore={initialStore}
            />
          </AlgoliaSearchProvider>
          <AlgoliaSearchProvider serverState={sliders.preroll}>
            <ProductSlider
              title="Pre-roll"
              filters={`root_types:"pre-roll"`}
              icon
              initialStore={initialStore}
            />
          </AlgoliaSearchProvider>
          <AlgoliaSearchProvider>
          <ProductSlider
              title="Extract"
              filters={`root_types:"extract"`}
              icon
            />
            <ProductSlider title="Vape" filters={`root_types:"vape"`} icon />
            <ProductSlider
              title="Edible"
              filters={`root_types:"edible"`}
              icon
            />
            <ProductSlider
              title="Tincture"
              filters={`root_types:"tincture"`}
              icon
            />
            <ProductSlider
              title="Topical"
              filters={`root_types:"topical"`}
              icon
            />
            <ProductSlider title="Gear" filters={`root_types:"gear"`} icon />
            <ProductSlider title="Merch" filters={`root_types:"merch"`} icon />
          </AlgoliaSearchProvider>
   

        </section>
        <section className="row pb-3 flex-column gap-400" id="why">
          <WhyPurpleLotus />
          <BlogSlider posts={posts} />
        </section>
      </main>
    </>
  );
}
