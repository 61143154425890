import Link from 'next/link'

import ArrowRight from "../../components/icons/arrow-right.svg";
import ArrowLeft from "../../components/icons/arrow-left.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation, Pagination, FreeMode, Scrollbar } from "swiper";

import styles from './CategorySlider.module.scss'
import Button from '../../components/Button';

import Flower from '../../components/icons/category/flower.svg'
import Preroll from '../../components/icons/category/pre-roll.svg'
import Extract from '../../components/icons/category/extract.svg'
import Vape from '../../components/icons/category/vape.svg'
import Edible from '../../components/icons/category/edible.svg'
import Tincture from '../../components/icons/category/tincture.svg'
import Topical from '../../components/icons/category/topical.svg'
import Gear from '../../components/icons/category/gear.svg'
import Merch from '../../components/icons/category/merch.svg'
import Leaf from "../../components/icons/category/leaf.svg";

export default function Slider({ title }) {

  const categories = [

    {
      category: "flower",
      href: "/shop/flower",
      icon: <Flower />,
    },
    {
      category: "pre-roll",
      href: "/shop/pre-roll",
      icon: <Preroll />,
    },
    {
      category: "extract",
      href: "/shop/extract",
      icon: <Extract />,
    },
    {
      category: "vape",
      href: "/shop/vape",
      icon: <Vape />,
    },
    {
      category: "edible",
      href: "/shop/edible",
      icon: <Edible />,
    },
    {
      category: "tincture",
      href: "/shop/tincture",
      icon: <Tincture />,
    },
    {
      category: "topical",
      href: "/shop/topical",
      icon: <Topical />,
    },
    {
      category: "gear",
      href: "/shop/gear",
      icon: <Gear />,
    },
    {
      category: "merch",
      href: "/shop/merch",
      icon: <Merch />,
    },
  ];
  
  const swiperConfig = {
    modules: [Navigation, Pagination, FreeMode],
    className: styles.categorySwiper,
    touchEventsTarget: "wrapper",
    breakpoints: {
      1024: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 14,
      },
      550: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      0: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      prevEl: `#category-prev`,
      nextEl: `#category-next`,
      clickable: true,
    },
  };
  

  return (
    <>
      <div>

        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Leaf />
            </div>
            <h2>{title}</h2>
          </div>
          <Button type="link" href='/shop' className="sliderLink" prefetch={false} ariaLabel="Shop All">
            <span>shop all</span>
          </Button>
        </div>

        <Swiper {...swiperConfig}>
           <div className={styles.prev} id={`category-prev`}>
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <ArrowLeft />
            </Button>
          </div>
          <div className={styles.next} id={`category-next`}>
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <ArrowRight />
            </Button>
          </div>

          {categories
            .map(category =>
              <SwiperSlide key={category.category} className={styles.slide}>
                <Link href={category.href} passHref>
                  <div className={styles.icon}>{category.icon}</div>
                    <div className={styles.slideTitle}>{category.category}</div>
                </Link>
              </SwiperSlide>
            )
          }

        </Swiper>
      </div>
    </>
  );
}

